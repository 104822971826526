<template>
    <div class="global-content media">
        <div class="glo-nav">媒体文件</div>
        <van-collapse v-model="activeNames" accordion>
            <van-collapse-item title="筛选条件" name="1">
                <van-cell title="按媒体类型" :value="filter.type.value " is-link @click="filter.type.show=true"/>
                <van-cell title="按时间段查询" :value="filter.time.value" is-link @click="filter.time.show = true"/>
                <van-button type="primary" class="btn-filter" @click="filterMedia">筛选</van-button>
            </van-collapse-item>
        </van-collapse>
        <!-- 媒体类型 -->
        <van-action-sheet v-model="filter.type.show" :actions="filter.type.actions" @select="typeSelect" />
         <!-- 筛选时间段 -->
        <van-calendar ref="time" v-model="filter.time.show" type="range" :min-date="filter.time.minDate" :max-date="filter.time.maxDate" @confirm="timeConfirm" color="#07c160"/>
        <div class="inner">
            <div v-if="!emptyData">
                <div v-if="dataList.length != 0">
                    <van-list v-model="loading" :finished="finished" finished-text="没有更多了"  @load="onLoad" :immediate-check="false">
                        <div class="block global-shadow">
                            <div v-if="requestTypeValue === 2">
                                <van-row gutter="20">
                                    <van-col span="12" class="glo-relative single-img" v-for="(item,index) in dataList" :key="index" @click="previewImage(item.filePath)">
                                        <div class="img">
                                            <img :src="item.filePath"/>
                                            <div class="title bottom-2">{{item.fileName}}</div>
                                        </div>
                                    </van-col>
                                </van-row>
                            </div>
                            <div v-else>
                                <van-row gutter="20">
                                    <van-col span="12" class="glo-relative single-img" v-for="(item,index) in dataList" :key="index" >
                                        <div class="img">
                                            <div class="curtain"></div>
                                            <div class="title">{{item.fileName}}</div>
                                            <div class="btn-0" @click="playVideo(item.filePath)">
                                                <van-icon name="play-circle-o" color="#0eb9ab" size="45" />
                                            </div>
                                        </div>
                                    </van-col>
                                </van-row>
                            </div>
                        </div>
                    </van-list>
                </div>
                <div v-else><van-loading class="z-loading" size="24px" vertical>加载中...</van-loading></div>
            </div>
            <div v-else><van-empty description="没有更多数据了" /></div>
        </div>
        <!-- 视频弹出框 -->
        <van-popup v-model="videoBox">
            <div class="video-box">
                <video ref="video" width="360" height="240" :src="videoUrl" controls></video>
            </div>
        </van-popup>
        <tabBar/>
    </div>
</template>

<script>
import tabBar from '@/components/slope/NVtabbar.vue'
import { ImagePreview } from 'vant';
import moment from "moment";

export default {
  name: 'Media',
  components: {
    tabBar
  },
  data(){
      return{
        projectCode:null,
        activeNames:'',
        emptyData:false,
        requestTypeValue:2,//默认是图片，图片为2，视频为1
        filter:{
            type:{
                code:'2',
                value:'图片',
                show:false,
                actions:[{name:'图片',id:2},{name:'视频',id:1}]
            },
            time:{
                start:'',
                end:'',
                minDate: new Date(2018, 0, 1),
                maxDate: new Date(),
                value:'',
                show:false
            }
        },
        dataList:[],
        loading:false,
        finished:false,
        pageNum:1,
        pageSize:10,
        videoBox:false,
        videoUrl:'',
        visitor:1
      }
  },
  mounted(){
    this.projectCode = window.sessionStorage.getItem('projectCode');
    this.onLoad();
  },
  methods:{
      previewImage(thumb){
          ImagePreview([thumb]);
      },
      // 筛选媒体类型
      typeSelect(e){
          this.filter.type.value = e.name;
          this.filter.type.code = e.id;
          this.filter.type.show = false;
      },
      // 筛选时间确认事件
      timeConfirm(date) {
        const [start, end] = date;
        this.filter.time.show = false;
        this.filter.time.start = moment(start).format("yyyy-MM-DD");
        this.filter.time.end = moment(end).format("yyyy-MM-DD");
        this.filter.time.value = `${this.filter.time.start} 至 ${this.filter.time.end}`;
      },
      // 向服务端发送筛选请求
      filterMedia(){
          this.requestTypeValue = this.filter.type.code;
          this.activeNames= '';
          this.dataList = [];
          this.pageNum = 1;
          this.finished=false;
          this.onLoad();
      },
      // h265视频转mp4
      h265toMp4(url){

        return new Promise( resolve => {
            this.$api.NEWBRIDGE.h265toMp4(url).then( d => {
                resolve(d)
            })
        })
        
    },
      // 播放视频
      playVideo(url){
        this.$dialog.confirm({
            title: '提示',
            message: '播放视频将消耗流量，点击确定继续播放',
        })
        .then(() => {
            const toast = this.$toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: '请稍等，视频转码中~',
            });
            // h264转mp4
            this.h265toMp4(url).then( newUrl => {
                this.$toast.clear();
                this.videoUrl = newUrl;
                this.videoBox = true;
            })
        })
        .catch(() => {});
      },
      // 分页加载媒体文件
      onLoad(){
            const postData = {
                fileType:this.requestTypeValue === 2 ? 'image' : 'video',
                projectCode:this.projectCode,
                beginTime:this.filter.time.start ? `${this.filter.time.start} 00:00:00` : void 0,
                endTime:this.filter.time.end ? `${this.filter.time.end} 23:59:59` :void 0
            } 
            this.$api.NEWBRIDGE.cameraFilePage(this.pageNum,this.pageSize,postData).then((data)=>{
                this.dataList = this.dataList.concat(data);
                this.dataList.length == 0 ? this.emptyData = true : this.emptyData = false;
                data.length < this.pageSize ? this.finished = true : void 0
                this.loading = false;
                this.pageNum ++;
            })
      }
  }
}
</script>

<style lang="scss" scoped>
.global-content{
    text-align: left;
}
.van-empty{margin: 25% 0;}
.btn-filter{width: 25%;height: 2rem;margin: 4% auto;display: block;}
.inner{
    border-radius: 10px;
    margin-bottom: 10%;
    .block{
        padding: 2%;
        margin-top: 6px;
        background-color: #fff;
        border-radius: 10px;
        .single-img{
            margin-bottom: 10px;
        }
        .title{
            font-size: 0.9rem;
            font-weight: bold;
            line-height: 2;
            color: #525252;
            width: calc(100% - 10px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            position: absolute;
            bottom: 0.3%;
            background: #cccccccc;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            padding: 2px 10px;
            box-sizing: border-box;
        }
        .bottom-2{
            bottom: 2%;
        }
        .time{font-size: .75rem;color: #616161;opacity: 0.6;line-height: 1.5;}
        .img{
            img{
                width: 100%;
                height: 170px;
                border-radius: 5px;
            }
            .curtain{
                background-color: #525252;
                width: 100%;
                height: 170px;
                border-radius: 5px;
            }
        }
        
        .btn-0{
            position: absolute;
            top: 28%;
            left: 40%;
        }
    }
}
</style>